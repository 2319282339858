import React from 'react'
import Features from '../components/Contact/Contact'

const Contact = () => {
  return (
    <Features />
  )
}

export default Contact
