import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { menuData } from '../../assets/data/MenuData'
import Bars from '../../assets/images/bars.svg';
import { Button } from '../Button';

const Nav = styled.nav`
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 100;
  position: fixed;
  width: 100%;
`

const NavLink = css`
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
`

const Logo = styled(Link)`
  ${NavLink}
  font-style: italic;
`

const MenuBars = styled.i`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    background-image: url(${Bars});
    background-size: contain;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 25%);
  }
`

const NavMenu = styled.div`
  display: flex;
  align-item: center;
  margin-right: -48px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const NavMenuLinks = styled(Link)`
  ${NavLink}
`

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px; 

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Navbar = ({ toggle }) => {
  // change navbar background
  const [navbar, setNavbar] = useState(false)
  const location = useLocation()

  const changeBackground = () => {
    // check if scroll down
    if (window.pageYOffset >= 60) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    const watchScroll = () => {
      window.addEventListener('scroll', changeBackground)
    }

    watchScroll()

    return () => {
      window.removeEventListener('scroll', changeBackground)
    }
  }, [])

  let style = {
    background: navbar || location.pathname !== "/" ? '#636c59' : 'transparent',
    transition: '0.4s'
  }
  const scrollToTop = () => {
    console.log('Scrolling to top');
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 使用平滑滚动
    });
  };

  return (
    <Nav style={style}>
      <Logo to="/" onClick={scrollToTop}>Susu Beauty</Logo>
      <MenuBars onClick={toggle}/>
      <NavMenu>
        {menuData.map((item, index) => (
          <NavMenuLinks to={item.link} key={index} onClick={() => item.title.toLowerCase() === 'home' && scrollToTop()}>
            {item.title}
          </NavMenuLinks>
        ))}
      </NavMenu>
      <NavBtn>
        <Button to="/contact" primary="true">
          Contact Us
        </Button>
      </NavBtn>
    </Nav>
  )
}

export default Navbar
