import React from 'react'
import { nail_price } from '../../assets/images'
import styled from 'styled-components'

export const Nail = () => {
  return (
    <NailContainer>
      <NailPrice src={nail_price} alt="Nail Price" />
    </NailContainer>
  )
}

const NailContainer = styled.div`
  flex: 1;
`

const NailPrice = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  padding: 20px;

  @media (min-width: 768px) {
    width: 50%;
    max-width: 800px;
    margin: 0 auto;
    display: block;
  }
`
